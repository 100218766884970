<template>
  <v-dialog
    v-model="isShowModal"
    width="90%">
    <v-card>
      <material-card
        :title="$t('thirdParty.updateInfoReceiveVouchers')"
        tile
        color="#01baef"
        full-width
      >
        <v-container>
          <v-layout wrap>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                :label="$t('user.fullname')"
                v-model="fullname"
              />
            </v-flex>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                :label="$t('user.phone')"
                v-model="phone"
              />
            </v-flex>
            <v-flex
              xs12
              md12
            >
              <v-text-field
                :label="$t('user.email')"
                v-model="email"
              />
            </v-flex>
          </v-layout>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          small
          color="#ff6d6d"
          style="color: #fff;"
          @click="onConfirm()"
        >{{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    phone: null,
    email: null,
    fullname: null,
    isShowModal: false,
    userId: null
  }),
  methods: {
    /**
     * Confirm create delivery info
     */
    onConfirm: function () {
      let filter = {
        userId: this.userId,
        phone: this.phone,
        email: this.email,
        fullname: this.fullname
      }
      this.CREATE_LIEN_VIET_DELIVERY_INFO(filter).then(
        function () {
          this.isShowModal = false
          this.$emit('createDeliveryInfoSuccess')
        }.bind(this)
      )
    },
    onShowModal: function () {
      let viVietSession = sessionStorage.getItem('viVietSession')
      viVietSession = JSON.parse(viVietSession)
      this.userId = viVietSession[4]
      this.isShowModal = true
      let filter = {
        params: {
          userId: this.userId
        }
      }
      this.GET_LIEN_VIET_DELIVERY_INFO(filter).then(
        function (res) {
          let data = res.data
          this.phone = data.phone
          this.email = data.email
          this.fullname = data.fullname
        }.bind(this)
      )
    },
    ...mapActions([
      'GET_LIEN_VIET_DELIVERY_INFO',
      'CREATE_LIEN_VIET_DELIVERY_INFO'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .modal {
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .v-card {
    margin-bottom: 0px !important;
    margin-top: 21px !important;
  }
</style>
